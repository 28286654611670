import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css']
})
export class RosterComponent implements OnInit {
  loading:any = true;
  lookingAtRoster:any = false;
  ignoreScroll:any = false;
  staff:any = [];
  staff_command:any = [];

  availableMedals:any = [];

  constructor(private http:HttpClient, private router: Router) {
    this.http.get("service.php?action=get_maintenance").subscribe(
    response => { 
      if(response['maintenance']) { this.router.navigateByUrl('/maintenance'); }
    },
    error => {  });
    this.http.get("service.php?action=get_staff").subscribe(
    response => { 
      this.staff = response['staff']; 
      for(let i = 0; i < this.staff.length; i++){
        if(this.staff[i].medals.length > 0) { this.staff[i].medals = this.staff[i].medals.split(";"); }
        if(this.returnIsCommandRank(this.staff[i])){ this.staff_command.push(this.staff[i]); }
      }
    },
    error => {  });
    this.http.get("service.php?action=get_medals").subscribe(
      response => {  this.availableMedals = response['medals']; },
      error => {  });
  }

  ngOnInit() {
    this.loading = true;
    setTimeout(()=>{
      this.loading = false;
    }, 1000);
  }

  returnAvatarImage(member){
    if(member.avatar == ''){
      return 'url(https://www.77th-jsoc.com/dash/members/member-default.jpeg)';
    } else {
      return 'url('+ member.avatar +')';
    }
  }

  returnIsCommandRank(member) {
    let isCommand = false;
    if(member.rank_id <= 4){
      isCommand = true;
    }
    return isCommand;
  }

  returnMedalImage(medal_id) {
    let medal = this.availableMedals.filter(medal => medal.id == medal_id);
    if(medal.length > 0) { return medal[0].image; }
  }

  returnMedalName(medal_id){
    let medal = this.availableMedals.filter(medal => medal.id == medal_id);
    if(medal.length > 0) { return medal[0].name; }
  }

}
