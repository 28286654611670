import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

declare var self;
declare var ts3v_display;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  loading:any = true;
  moveTop_1:any = false;
  moveTop_2:any = false;
  bulletStage = 0;
  bulletInterval:any;
  initialized:any = false;
  showinfo:any = false;
  allowedToScroll = true;
  selectedImage:any = 1;
  aboutInterval:any;
  recruitment = { recruitment_open:false, recruitment_link:"" };
  
  settingsInterval:any;

  menuOpen:any = false;

  aboutHeaders:any = ["Ground Zero", "Tactical Vision", "The Community", "Private Gameplay"];
  aboutBody:any = [
    "It all started with a group of friends playing Arma 3 when it was first released in 2014. They were trying out a number of different game modes but soon discovered there weren't any with a good focus on cooperation and tactical gameplay. In order to satisfy the urge for more in-depth gameplay experience, they turned to their own self-organized missions and the 77th JSOC was born a few months later. After countless private missions played and seeing how much fun organized tactical gameplay could be like, it was decided to try and publicly share it with the rest of the Arma 3 community.",
    "The next step happened in mid-2014 with the opening of the first 77th JSOC public server. It ran an early version of the cooperative Invade & Annex game mode. The essence of the public servers is to bring the players a taste of coming together as a group and completing different tasks in a military simulated environment, no matter their skills or experience. The servers have been continuously modified to further enhance the experience since, but always with the tactical realism gameplay in mind.",
    "Over the years, the group grew from just a couple of members with the same vision, to an international community consisting of people from across the globe sharing it. We’re fortunate enough to have built very constructive and positive engagement with all of our players across many different platforms like TeamSpeak, Discord and our social media. Thanks to the support of those players in our community, we are still able to run the servers and keep developing our vision of tactical gameplay along with them. To allow as many people as possible to be able to participate, we run our servers without any server-side mods, but just a few small client-side mods supported.",
    "For those who want to enjoy a more different and modded experience with Arma 3 MilSim gameplay we also have the private aspect of our community. We run regular recruitment cycles to give any public player the opportunity to join our ranks as a full-fledged member and represent 77th JSOC to the world. Our private structure and chain of command are based around the US JSOC and more so the Special Forces style of gameplay. That means we play a variety of missions including combat search and rescue, reconnaissance, counter-terrorism, hostage rescue and many more. We have multiple campaign storyline missions that are based upon a custom written storyline that changes with every action taken. Our training program even though heavily modified is based on real-life US Navy/Army tactics, movements and procedures. "
  ]

  upcoming:any = [];


  news:any = [];
  currentNews:any = { uid:99999 };

  staff:any = [];
  staff_command:any = [];

  availableMedals:any = [];

  currentPage:any = 0;

  ignoreScroll:any = false;

  modal:any = { open:false, context:"", obj:{} }

  constructor(private http:HttpClient, private route: ActivatedRoute, private router: Router) {
    self = this;
    this.http.get("service.php?action=get_maintenance").subscribe(
    response => { 
      if(response['maintenance']) { this.router.navigateByUrl('/maintenance'); }
    },
    error => {  });
    this.http.get("service.php?action=get_news_latest_news").subscribe(
    response => { this.news = response['news']; },
    error => {  });
    this.http.get("service.php?action=get_staff").subscribe(
    response => { 
      this.staff = response['staff']; 
      for(let i = 0; i < this.staff.length; i++){
        if(this.staff[i].medals.length > 0) { this.staff[i].medals = this.staff[i].medals.split(";"); }
        if(this.returnIsCommandRank(this.staff[i])){ this.staff_command.push(this.staff[i]); }
      }
    },
    error => {  });
    this.http.get("service.php?action=get_medals").subscribe(
    response => {  this.availableMedals = response['medals']; },
    error => {  });
    let d = new Date();
    this.http.get("service.php?action=get_upcoming_events&timezone=" + d.getTimezoneOffset()).subscribe(
    response => { this.upcoming = response['dates']; },
    error => {  });
    this.http.get("service.php?action=get_settings").subscribe(
    response => { 
      this.recruitment = response['settings'];
      this.settingsInterval = setInterval(() => {
        this.http.get("service.php?action=get_settings").subscribe(
        response => { 
          this.recruitment = response['settings'];
        },
        error => {  });
      }, 5000);
    },
    error => {  });
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.doScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.doScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.doScroll(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelEdge(event: any) {
    this.doScroll(event);
  }

  switchMenu(){ this.menuOpen = !this.menuOpen; }

  switchImage(image) { 
    this.selectedImage = image; 
    clearInterval(this.aboutInterval);
    this.aboutInterval = setInterval(() => {
      if(this.selectedImage == 4) {
        this.selectedImage = 1;
      } else {
        this.selectedImage = this.selectedImage + 1;
      }
    }, 15000);
  }

  doScroll(event) {
    if(!self.lookingAtRoster && !self.ignoreScroll && self.allowedToScroll) {
        if(window.innerWidth > 1080 && window.innerHeight > 769){
          var event = window.event || event; // old IE support
          var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
          if(self.currentPage < document.getElementsByClassName('section').length - 1 && self.allowedToScroll) { 
              if(delta < 0 ) { 
                  self.scrollToPage(self.currentPage + 1); self.allowedToScroll = false; 
                  setTimeout(function(){
                      self.allowedToScroll = true;
                  }, 1000);
              }
          }
          if(self.currentPage > 0 && self.allowedToScroll) { 
              if(delta > 0) { 
                  self.scrollToPage(self.currentPage - 1);self.allowedToScroll = false;
                  setTimeout(function(){
                    self.allowedToScroll = true;
                  }, 1000);
              }
          }
      }
    }
  }


  scrollToPage(page) {
    let pageDifference = Math.abs(page - this.currentPage);
    var navbar = document.getElementsByClassName('navbar')[0];
    var navbarMobile = document.getElementsByClassName('mobile-menu')[0];
    var elem = document.getElementsByClassName('section')[page];
    var calc = page * document.body.offsetHeight;
  
    elem.scrollIntoView({behavior: "smooth", block: "center"});
    for(var i = 0; i <  navbar.getElementsByTagName('div').length; i++) { navbar.getElementsByTagName('div')[i].className = ""; }
    for(var i = 0; i <  navbarMobile.getElementsByTagName('span').length; i++) { navbarMobile.getElementsByTagName('span')[i].className = ""; }
    navbarMobile.getElementsByTagName('span')[page].className = "active";
    navbar.getElementsByTagName('div')[page].className = "active";
    this.currentPage = page;
    if(this.currentPage == 1) {
      clearInterval(this.aboutInterval);
      this.aboutInterval = setInterval(() => {
        if(this.selectedImage == 4) {
          this.selectedImage = 1;
        } else {
          this.selectedImage = this.selectedImage + 1;
        }
      }, 15000);
    }
    this.menuOpen = false;
  }

  switchBullets() {
    if(this.bulletStage == 0) {
        for(var i = 1; i < 7; i++){
            document.getElementsByClassName('bullet' + i)[0].className = "bullet" + i + " stage1";
        }
        this.bulletStage = 1;
    } else {
        for(var i = 1; i < 7; i++){
            document.getElementsByClassName('bullet' + i)[0].className = "bullet" + i;
        }
        this.bulletStage = 0;
    }
  }

  IgnoreScroll(ignore) { this.ignoreScroll = ignore; }


  hoverNews(news){ this.currentNews = news; }
  unhoverNews() { this.currentNews = { uid:99999 } }

  ngAfterViewInit(){
    this.route.paramMap.subscribe(params => {
      let returnTo = params.get("returnTo");
      var navbar = document.getElementsByClassName('navbar')[0];
      var navbarMobile = document.getElementsByClassName('mobile-menu')[0];
      if(returnTo == 'roster') {
        var calc = 3 * document.body.offsetHeight;
        document.body.scrollTo({
        top: calc,
        left: 0,
        });
        for(var i = 0; i <  navbar.getElementsByTagName('div').length; i++) { navbar.getElementsByTagName('div')[i].className = ""; }
        for(var i = 0; i <  navbarMobile.getElementsByTagName('span').length; i++) { navbarMobile.getElementsByTagName('span')[i].className = ""; }
        navbarMobile.getElementsByTagName('span')[3].className = "active";
        navbar.getElementsByTagName('div')[3].className = "active";
        this.currentPage = 3;
      } else if(returnTo == 'events') {
        var calc = 4 * document.body.offsetHeight;
        document.body.scrollTo({
        top: calc,
        left: 0,
        });
        for(var i = 0; i <  navbar.getElementsByTagName('div').length; i++) { navbar.getElementsByTagName('div')[i].className = ""; }
        for(var i = 0; i <  navbarMobile.getElementsByTagName('span').length; i++) { navbarMobile.getElementsByTagName('span')[i].className = ""; }
        navbarMobile.getElementsByTagName('span')[4].className = "active";
        navbar.getElementsByTagName('div')[4].className = "active";
        this.currentPage = 4;
      } else if(returnTo == 'news') {
        var calc = 2 * document.body.offsetHeight;
        document.body.scrollTo({
        top: calc,
        left: 0,
        });
        for(var i = 0; i <  navbar.getElementsByTagName('div').length; i++) { navbar.getElementsByTagName('div')[i].className = ""; }
        for(var i = 0; i <  navbarMobile.getElementsByTagName('span').length; i++) { navbarMobile.getElementsByTagName('span')[i].className = ""; }
        navbarMobile.getElementsByTagName('span')[2].className = "active";
        navbar.getElementsByTagName('div')[2].className = "active";
        this.currentPage = 2;
      }
    })
    this.loading = true;
    setTimeout(()=>{
      this.loading = false;
      this.loadScript();
    }, 1000);
    setTimeout(()=>{
      this.initialized = true;
      setTimeout(()=>{
        this.showinfo = true;
      }, 750);
    }, 1400);
    this.switchBullets();
    clearInterval(this.bulletInterval);
    this.bulletInterval = setInterval(() => {
      this.switchBullets(); 
    }, 10000);
    setTimeout(()=>{
      this.moveTop_1 = true;
      this.moveTop_2 = true;
    }, 10000);
  }

  linkTo(href) { window.location = href; }
  loadScript() {
    var ts3v_url_1 = "https://www.tsviewer.com/ts3viewer.php?ID=1119559&text=a6a6a6&text_size=12&text_family=1&text_s_color=ffffff&text_s_weight=normal&text_s_style=normal&text_s_variant=normal&text_s_decoration=none&text_i_color=&text_i_weight=normal&text_i_style=normal&text_i_variant=normal&text_i_decoration=none&text_c_color=&text_c_weight=normal&text_c_style=normal&text_c_variant=normal&text_c_decoration=none&text_u_color=dedede&text_u_weight=normal&text_u_style=normal&text_u_variant=normal&text_u_decoration=none&text_s_color_h=&text_s_weight_h=bold&text_s_style_h=normal&text_s_variant_h=normal&text_s_decoration_h=none&text_i_color_h=ffffff&text_i_weight_h=bold&text_i_style_h=normal&text_i_variant_h=normal&text_i_decoration_h=none&text_c_color_h=&text_c_weight_h=normal&text_c_style_h=normal&text_c_variant_h=normal&text_c_decoration_h=none&text_u_color_h=&text_u_weight_h=bold&text_u_style_h=normal&text_u_variant_h=normal&text_u_decoration_h=none&iconset=default";
    ts3v_display.init(ts3v_url_1, 1119559, 100);
  }
  
  openEvent(ev){ this.modal = { open:true, context:'view', obj:ev }; }
  closeModal() { this.modal.open = false; }

  returnEventsSorted(events) {
    return events.sort((a, b) => parseInt(b.time_start.substr(0,2)) - parseInt(a.time_start.substr(0,2)));
  }

  returnAvatarImage(member){
    if(member.avatar == ''){
      return 'url(https://www.77th-jsoc.com/dash/members/member-default.jpeg)';
    } else {
      return 'url('+ member.avatar +')';
    }
  }

  returnIsCommandRank(member) {
    let isCommand = false;
    if(member.rank_id <= 4){
      isCommand = true;
    }
    return isCommand;
  }

  returnDay(upcoming) {
    let dt = new Date(upcoming['date'] + "T00:00:00");
    let dayName = "";
    if(dt.getDay() == 0) { dayName = "SUN"; }
    else if(dt.getDay() == 1) { dayName = "MON"; }
    else if(dt.getDay() == 2) { dayName = "TUE"; }
    else if(dt.getDay() == 3) { dayName = "WED"; }
    else if(dt.getDay() == 4) { dayName = "THU"; }
    else if(dt.getDay() == 5) { dayName = "FRI"; }
    else if(dt.getDay() == 6) { dayName = "SAT"; }
    return dayName;
  }

  returnDate(upcoming) { return upcoming.date.substring(8, 10) }
  returnMonthUpcoming(upcoming) {
    let dt = new Date(upcoming['date']);
    let month = "";
    let dateMonth = dt.getMonth();
    if(dateMonth == 0) { month = 'January'; }
    else if(dateMonth == 1) { month = 'February'; }
    else if(dateMonth == 2) { month = 'March'; }
    else if(dateMonth == 3) { month = 'April'; }
    else if(dateMonth == 4) { month = 'May'; }
    else if(dateMonth == 5) { month = 'June'; }
    else if(dateMonth == 6) { month = 'July'; }
    else if(dateMonth == 7) { month = 'August'; }
    else if(dateMonth == 8) { month = 'September'; }
    else if(dateMonth == 9) { month = 'October'; }
    else if(dateMonth== 10) { month = 'November'; }
    else if(dateMonth == 11) { month = 'December'; }
    return month;
  }

  returnMonthShort(month) {
    let monthInt = parseInt(month);
    if(monthInt == 1) { return "Jan"; }
    else if(monthInt == 2) { return "Feb"; }
    else if(monthInt == 3) { return "Mar"; }
    else if(monthInt == 4) { return "Apr"; }
    else if(monthInt == 5) { return "May"; }
    else if(monthInt == 6) { return "Jun"; }
    else if(monthInt == 7) { return "Jul"; }
    else if(monthInt == 8) { return "Aug"; }
    else if(monthInt == 9) { return "Sep"; }
    else if(monthInt == 10) { return "Oct"; }
    else if(monthInt == 11) { return "Nov"; }
    else if(monthInt == 12) { return "Dec"; }
  }

  returnNewsDate(news) {
    let dt_split = news.posted_at.split("/");
    let dt = dt_split[0] + " " + this.returnMonthShort(dt_split[1]) + " " + dt_split[2];
    return dt;
  }

  returnMedalImage(medal_id) {
    let medal = this.availableMedals.filter(medal => medal.id == medal_id);
    if(medal.length > 0) { return medal[0].image; }
  }

  returnMedalName(medal_id){
    let medal = this.availableMedals.filter(medal => medal.id == medal_id);
    if(medal.length > 0) { return medal[0].name; }
  }


}
