import { Component, OnInit } from '@angular/core';

import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  constructor(private http:HttpClient, private router: Router) {
    this.http.get("service.php?action=get_maintenance").subscribe(
    response => { 
      if(!response['maintenance']) { this.router.navigateByUrl('/home'); }
    },
    error => {  });
  }

  ngOnInit() {
  }

}
