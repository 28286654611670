import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-current-news',
  templateUrl: './current-news.component.html',
  styleUrls: ['./current-news.component.css']
})
export class CurrentNewsComponent implements AfterViewInit {
  loading:any = true;

  currentNews:any;

  constructor(private http:HttpClient, private route: ActivatedRoute) { }

  ngAfterViewInit(){
    this.route.paramMap.subscribe(params => {
      let newsID = params.get("id");
      this.http.get("service.php?action=get_single_news&id=" + newsID).subscribe(
        response => { 
          this.currentNews = response['news'][0]; 
          setTimeout(()=>{
            this.loading = false;
          }, 1000);
        },
        error => {  });
    });
  }

  returnImage() {
    return "url('"+ this.currentNews.image +"')";
  }


  returnMonthShort(month) {
    let monthInt = parseInt(month);
    if(monthInt == 1) { return "Jan"; }
    else if(monthInt == 2) { return "Feb"; }
    else if(monthInt == 3) { return "Mar"; }
    else if(monthInt == 4) { return "Apr"; }
    else if(monthInt == 5) { return "May"; }
    else if(monthInt == 6) { return "Jun"; }
    else if(monthInt == 7) { return "Jul"; }
    else if(monthInt == 8) { return "Aug"; }
    else if(monthInt == 9) { return "Sep"; }
    else if(monthInt == 10) { return "Oct"; }
    else if(monthInt == 11) { return "Nov"; }
    else if(monthInt == 12) { return "Dec"; }
  }

  returnNewsDate(news) {
    let dt_split = news.posted_at.split("/");
    let dt = dt_split[0] + " " + this.returnMonthShort(dt_split[1]) + " " + dt_split[2];
    return dt;
  }

}
