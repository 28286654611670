import { Component, AfterViewInit, HostListener} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

declare var self;
declare var ts3v_display;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  lookingAtRoster:any = false;
  ignoreScroll:any = false;
  initialized:any = false;
  showinfo:any = false;
  loading:any = [true, true, true];

  title = 'unit-site';
  currentPage:any = 0;

  ngAfterViewInit(){
    self = this;
    this.LoadImage();
    this.LoadImage1();
    this.LoadImage2();
    setTimeout(()=>{
      this.initialized = true;
      setTimeout(()=>{
        this.showinfo = true;
      }, 750);
    }, 1400);
    this.loadScript();
  }

  constructor(private http:HttpClient, private router:Router){

  }

  linkTo(href) { window.location = href; }


  loadScript() {
      var ts3v_url_1 = "https://www.tsviewer.com/ts3viewer.php?ID=1119559&text=a6a6a6&text_size=12&text_family=1&text_s_color=ffffff&text_s_weight=normal&text_s_style=normal&text_s_variant=normal&text_s_decoration=none&text_i_color=&text_i_weight=normal&text_i_style=normal&text_i_variant=normal&text_i_decoration=none&text_c_color=&text_c_weight=normal&text_c_style=normal&text_c_variant=normal&text_c_decoration=none&text_u_color=dedede&text_u_weight=normal&text_u_style=normal&text_u_variant=normal&text_u_decoration=none&text_s_color_h=&text_s_weight_h=bold&text_s_style_h=normal&text_s_variant_h=normal&text_s_decoration_h=none&text_i_color_h=ffffff&text_i_weight_h=bold&text_i_style_h=normal&text_i_variant_h=normal&text_i_decoration_h=none&text_c_color_h=&text_c_weight_h=normal&text_c_style_h=normal&text_c_variant_h=normal&text_c_decoration_h=none&text_u_color_h=&text_u_weight_h=bold&text_u_style_h=normal&text_u_variant_h=normal&text_u_decoration_h=none&iconset=default";
      ts3v_display.init(ts3v_url_1, 1119559, 100);
  }

  LoadImage() {
    var self = this;
    var newImg = new Image;
    newImg.onload = function() {
      setTimeout(function(){ self.loading[0] = false; }, 500);
    }
    newImg.src = 'assets/layer3.png';
  }
  LoadImage1() {
    var self = this;
    var newImg = new Image;
    newImg.onload = function() {
      setTimeout(function(){ self.loading[1] = false; }, 500);
    }
    newImg.src = 'assets/layer1.png';
  }
  LoadImage2() {
    var self = this;
    var newImg = new Image;
    newImg.onload = function() {
      setTimeout(function(){ self.loading[2] = false; }, 500);
    }
    newImg.src = 'assets/layer2-2.png';
  }
  
  returnRoute() { return this.router.url; }

}
