import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  loading:any = false;

  datesOfMonth:any = [];
  currentDate = new Date();
  currentMonth = 0;
  currentYear = 0;
  currentMonthStart = 0;

  today:any;

  currentMonthDates:any = [];
  emptyDates:any = [];
  lastDates:any = [];

  modal:any = { open:false, context:"", obj:{} }

  constructor(private http:HttpClient, private router:Router) {
    this.today = new Date();
    this.currentDate = new Date();
    this.currentMonth = this.currentDate.getMonth();
    this.currentYear = this.currentDate.getFullYear();
    let dt_start = new Date();
    dt_start.setDate(1);
    this.currentMonthStart = dt_start.getDay();
    this.generateEmptyArray(this.currentMonthStart);
    this.generateDatesArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
    this.generateLastArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
    this.http.get("service.php?action=get_maintenance").subscribe(
    response => { 
      if(response['maintenance']) { this.router.navigateByUrl('/maintenance'); }
    },
    error => {  });
  }

  generateEmptyArray(num) {
    let d = new Date();
    this.emptyDates = [];
    if(num == 0) { num = 7; }
    if(num == 1) {
      // let dt = new Date(this.currentDate);
      // dt.setDate(0);
      // this.emptyDates.push({ day:dt.getDate(), month:dt.getMonth(), events:[]});
    } else {
      for(let i = 1; i < num; i++) {
        let dt = new Date(this.currentDate);
        dt.setDate((((num - i) -1) * -1));
        this.emptyDates.push({ day:dt.getDate(), month:dt.getMonth(), events:[]});
      }
    }
    let year = this.currentYear;
    let month = this.currentMonth;
    if(this.currentMonth == 0) {
      month = 12;
      year = this.currentYear -1;
    }
    if(this.emptyDates.length > 0) {
      this.http.get("service.php?action=get_events_for_period&year=" + year + "&month=" + month + "&day="+ this.emptyDates[0].day + "&day_end=31&timezone=" +  d.getTimezoneOffset()).subscribe(
      response => {
        let events = response['events'];
        for(let i = 0; i < events.length; i++) {
          let dt = events[i].date_at.substring(8, 10);
          let evDate = this.emptyDates.filter(empty => empty.day == dt);
          if(evDate.length > 0) {
            evDate[0].events.push(events[i]);
          }
        }
        // this.ngZone.run(() => { console.log('Outside Done!'); });
      },
      error => {  });
    }

  }

  generateDatesArray(num){
    let d = new Date();
    this.currentMonthDates = [];
    for(let i = 0; i < num; i++) {
      let cnt = i + 1;
      this.currentMonthDates.push({ day:cnt, month:this.currentMonth, events:[]});
    }
    let month = this.currentMonth + 1;
    this.http.get("service.php?action=get_events&year=" + this.currentYear + "&month=" + month + "&timezone=" +  d.getTimezoneOffset()).subscribe(
    response => {
      let events = response['events'];
      for(let i = 0; i < events.length; i++) {
        let dt = events[i].date_at.substring(8, 10);
        this.currentMonthDates[(parseInt(dt) - 1)].events.push(events[i]);
      }
      console.log(this.currentMonthDates);
      console.log(this.today.getDate());
      console.log(this.today.getMonth());
      // this.ngZone.run(() => { console.log('Outside Done!'); });
    },
    error => {  });
  }

  generateLastArray(num) {
    let d = new Date();
    this.lastDates = [];
    let final = (42 - this.emptyDates.length) - num;
    for(let i = 0; i < final; i++) {
      let dt = new Date(this.currentDate);
      dt.setDate(num + i + 1)
      this.lastDates.push({ day:dt.getDate(), month:dt.getMonth(), events:[]});
    }

    let year = this.currentYear;
    let month = this.currentMonth + 2;
    if(this.currentMonth == 11) {
      month = 0;
      year = this.currentYear + 1;
    }
    this.http.get("service.php?action=get_events_for_period&year=" + year + "&month=" + month + "&day="+ this.lastDates[0].day + "&day_end="+ this.lastDates[this.lastDates.length - 1].day +"&timezone=" +  d.getTimezoneOffset()).subscribe(
    response => {
      let events = response['events'];
      for(let i = 0; i < events.length; i++) {
        let dt = events[i].date_at.substring(8, 10);
        let evDate = this.lastDates.filter(last => last.day == dt);
        evDate[0].events.push(events[i]);
      }
      // this.ngZone.run(() => { console.log('Outside Done!'); });
    },
    error => {  });
  }

  nextMonth() {
    if(this.currentMonth < 11) {
      this.currentMonth += 1;
      this.currentDate.setMonth(this.currentMonth);
    } else {
      this.currentYear += 1;
      this.currentMonth = 0;
      this.currentDate.setFullYear(this.currentYear);
      this.currentDate.setMonth(this.currentMonth);
    }
    let dt_start = new Date(this.currentDate);
    dt_start.setDate(1);
    this.currentMonthStart = dt_start.getDay();
    this.generateEmptyArray(this.currentMonthStart);
    this.generateDatesArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
    this.generateLastArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
  }

  prevMonth() {
    if(this.currentMonth > 0) {
      this.currentMonth -= 1;
      this.currentDate.setMonth(this.currentMonth);
    } else {
      this.currentYear -= 1;
      this.currentMonth = 11;
      this.currentDate.setFullYear(this.currentYear);
      this.currentDate.setMonth(this.currentMonth);
    }
    let dt_start = new Date(this.currentDate);
    dt_start.setDate(1);
    this.currentMonthStart = dt_start.getDay();
    this.generateEmptyArray(this.currentMonthStart);
    this.generateDatesArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
    this.generateLastArray(this.returnDaysInMonth(dt_start.getMonth() + 1, dt_start.getFullYear()));
  }


  ngOnInit() {
    this.loading = true;
    setTimeout(()=>{
      this.loading = false;
    }, 1000);
  }

  openEvent(ev){ this.modal = { open:true, context:'view', obj:ev }; }
  closeModal() { this.modal.open = false; }

  returnEventsSorted(events) {
    return events.sort((a, b) => parseInt(b.time_start.substr(0,2)) - parseInt(a.time_start.substr(0,2)));
  }

  returnFirstDayOfMonth(month, year){ return new Date(year, month, 0); }
  returnDaysInMonth = function(month,year) { return new Date(year, month, 0).getDate(); };
  returnShortMonthName(idx) {
    let month = "";
    if(idx == 0) { month = "Jan"; }
    else if(idx == 1) { month = "Feb"; }
    else if(idx == 2) { month = "Mar"; }
    else if(idx == 3) { month = "Apr"; }
    else if(idx == 4) { month = "May"; }
    else if(idx == 5) { month = "Jun"; }
    else if(idx == 6) { month = "Jul"; }
    else if(idx == 7) { month = "Aug"; }
    else if(idx == 8) { month = "Sep"; }
    else if(idx == 9) { month = "Oct"; }
    else if(idx == 10) { month = "Nov"; }
    else if(idx == 11) { month = "Dec"; }
    return month;
  }
  returnMonthName(){
    let month = "";
    if(this.currentMonth == 0) { month = 'January'; }
    else if(this.currentMonth == 1) { month = 'February'; }
    else if(this.currentMonth == 2) { month = 'March'; }
    else if(this.currentMonth == 3) { month = 'April'; }
    else if(this.currentMonth == 4) { month = 'May'; }
    else if(this.currentMonth == 5) { month = 'June'; }
    else if(this.currentMonth == 6) { month = 'July'; }
    else if(this.currentMonth == 7) { month = 'August'; }
    else if(this.currentMonth == 8) { month = 'September'; }
    else if(this.currentMonth == 9) { month = 'October'; }
    else if(this.currentMonth == 10) { month = 'November'; }
    else if(this.currentMonth == 11) { month = 'December'; }
    return month;
  }
  returnFullYear() { return this.currentYear; }
  returnPad(n,length){
    var s=n+"",needed=length-s.length;
    if (needed>0) s=(Math.pow(10,needed)+"").slice(1)+s;
    return s;
  }

}
