import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RosterComponent } from './roster/roster.component';
import { EventsComponent } from './events/events.component';
import { NewsComponent } from './news/news.component';

import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { CurrentNewsComponent } from './current-news/current-news.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';


const appRoutes: Routes = [
  { path: 'home/:returnTo', component: HomeComponent },
  { path: 'roster',      component: RosterComponent },
  { path: 'events',      component: EventsComponent },
  { path: 'news',      component: NewsComponent },
  { path: 'current-news/:id',      component: CurrentNewsComponent },
  { path: 'maintenance',      component: MaintenanceComponent },
  { path: '',
    redirectTo: '/home/home',
    pathMatch: 'full'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RosterComponent,
    EventsComponent,
    NewsComponent,
    CurrentNewsComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true })
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
