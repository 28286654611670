import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  loading:any = true;
  news:any = [];

  constructor(private http:HttpClient, private router:Router) {
    this.http.get("service.php?action=get_maintenance").subscribe(
    response => { 
      if(response['maintenance']) { this.router.navigateByUrl('/maintenance'); }
    },
    error => {  });
    this.http.get("service.php?action=get_news").subscribe(
    response => { 
      this.news = response['news']; 
    },
    error => {  });
  }

  ngOnInit() {
    this.loading = true;
    setTimeout(()=>{
      this.loading = false;
    }, 1000);
  }


  returnMonthShort(month) {
    let monthInt = parseInt(month);
    if(monthInt == 1) { return "Jan"; }
    else if(monthInt == 2) { return "Feb"; }
    else if(monthInt == 3) { return "Mar"; }
    else if(monthInt == 4) { return "Apr"; }
    else if(monthInt == 5) { return "May"; }
    else if(monthInt == 6) { return "Jun"; }
    else if(monthInt == 7) { return "Jul"; }
    else if(monthInt == 8) { return "Aug"; }
    else if(monthInt == 9) { return "Sep"; }
    else if(monthInt == 10) { return "Oct"; }
    else if(monthInt == 11) { return "Nov"; }
    else if(monthInt == 12) { return "Dec"; }
  }

  returnNewsDate(news) {
    let dt_split = news.posted_at.split("/");
    let dt = dt_split[0] + " " + this.returnMonthShort(dt_split[1]) + " " + dt_split[2];
    return dt;
  }
}
